import {ecomAppDefID} from '../constants';

export async function addPage(sdk: IEditorSdk, pageId: string) {
  const productPageData = await sdk.tpa.add.component('', {
    appDefinitionId: ecomAppDefID,
    managingAppDefId: ecomAppDefID,
    componentType: sdk.document.tpa.TPAComponentType.Page,
    page: {
      pageId,
      shouldNavigate: false,
    },
  });

  return sdk.document.pages.setState('', {
    state: {
      [pageId]: [{id: productPageData.pageRef.id}],
    },
  });
}

export function addWidget(sdk: IEditorSdk, widgetId: string, addToAllPages: boolean): Promise<string> {
  return sdk.tpa.add.component('', {
    appDefinitionId: ecomAppDefID,
    componentType: sdk.tpa.TPAComponentType.Widget,
    widget: {
      widgetId,
      allPages: addToAllPages,
    },
  });
}
